.divbody{
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   background-position: center;
   background-image:url(../../logos/LoginBackground.jpg);
   width: 100%;
   height: 100%;
   font-family: Arial, Helvetica;
   letter-spacing: 0.02em;
    font-weight: 400;
   -webkit-font-smoothing: antialiased; 
  }

.divbody-middlebay{
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   background-position: center;
   background-color:#8F9864;
   width: 100%;
   height: 100%;
   font-family: Arial, Helvetica;
   letter-spacing: 0.02em;
    font-weight: 400;
   -webkit-font-smoothing: antialiased; 
  }

  .divbody-bristol{
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
   background-position: center;
   background-color:#1E376B;
   width: 100%;
   height: 100%;
   font-family: Arial, Helvetica;
   letter-spacing: 0.02em;
    font-weight: 400;
   -webkit-font-smoothing: antialiased; 
  }

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
   font-size: small;
}

 
.MuiInputBase-input.MuiInput-input{
   font-size: small;
}


.MuiFormControl-root.MuiTextField-root{
   width: -webkit-fill-available;
}


.MuiContainer-root.MuiContainer-fixed.MuiContainer-maxWidthLg{
   filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.30));
}

.MuiGrid-root.MuiGrid-item{
   padding: 1%;
}
